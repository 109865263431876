<template>
  <div class="page-bg-gradient relative min-h-full">
    <Headers :title="$t('Pool3 & 4')" :isback="false"></Headers>
    <div
      class="absolute right-0 top-31px bg-[#7fd5b299] filter blur-100px w-138px h-207px"
    />
    <div class="pt-112px px-24px">
      <div
        class="p-20px bg-[#ffffff1a] rounded-24px border border-[#ffffff0f] border-solid flex justify-between items-center"
      >
        <div class="flex items-center">
          <img
            src="~@/assets/new/CAKE.png"
            class="w-48px h-48px mr-8px"
            alt=""
            srcset=""
          />
          <div class="text-15px font-Gilroy">GRN</div>
        </div>
        <div class="text-right">
          <div class="text-21px italic font-extrabold">{{ balance }}</div>
          <div class="text-13px text-[#ffffffb3] mt-6px">
            {{ $t('head.balance') }}
          </div>
        </div>
      </div>

      <div
        class="mt-20px border border-[#ffffff0f] rounded-24px bg-[#ffffff1a] px-20px py-22px relative"
      >
        <div>
          <div class="flex justify-between">
            <div class="text-32px font-bold font-Gilroy">
              <span>{{ earn3 }}</span
              ><span class="ml-6px">UNC</span>
            </div>
          </div>
          <div class="text-[#ffffff99] text-16px font-Gilroy mt-8px">
            POOL3 EARNED
          </div>
          <div class="mt-29px">
            <div class="btn-sm btn-bg w-93px" @click="playwithdrawReward3">
              {{ $t('head.get') }}
            </div>
          </div>

          <div
            class="border border-[#ffffff33] rounded-16px mt-16px h-44px flex items-center"
          >
            <van-field
              v-model="amount3"
              class="rounded-full inp1"
              label-width="0"
              :placeholder="$t('head.inputAmount')"
            ></van-field>
          </div>
          <div class="mt-16px flex">
            <div
              @click="onApprove3"
              class="h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba814d] from-opacity-30 to-[#1453bf4d] to-opacity-30 border border-[#1453bf4d]"
            >
              <img
                src="~@/assets/new/06.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t('head.aa7') }}</span>
            </div>

            <div
              @click="onDeposit3"
              class="ml-25px h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba81] to-[#1453bf]"
            >
              <img
                src="~@/assets/new/20.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t('head.stake') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-20px p-20px bg-[#ffffff1a] rounded-24px border border-[#ffffff0f] border-solid"
      >
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <img
              src="~@/assets/new/star-token.png"
              class="w-48px h-48px mr-8px"
              alt=""
              srcset=""
            />
            <div class="text-15px font-Gilroy">STAR</div>
          </div>
          <div class="text-right">
            <div class="text-21px italic font-extrabold">{{ balanceStar }}</div>
            <div class="text-13px text-[#ffffffb3] mt-6px">
              {{ $t('head.balance') }}
            </div>
          </div>
        </div>
        <div class="mt-10px">
          <van-divider contentPosition="center" class="m-0">pool4 {{$t('head.hash')}}</van-divider>
          <div class="grid grid-cols-3 flex-1">
            <div class="text-center text-12px text-[#ffffff99]">
              <div>{{userHash}}</div>
              <div class="mt-6px">{{$t('userHashPwoer')}}</div>
            </div>
            <div class="text-center text-12px text-[#ffffff99]">
              <div>{{teamHash}}</div>
              <div class="mt-6px">{{$t('teamHashPwoer')}}</div>
            </div>
            <div class="text-center text-12px text-[#ffffff99]">
              <div>{{hashTotal}}</div>
              <div class="mt-6px">{{$t('totalHashPwoer')}}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-20px border border-[#ffffff0f] rounded-24px bg-[#ffffff1a] px-20px py-22px relative"
      >
        <div>
          <div class="flex justify-between">
            <div class="text-32px font-bold font-Gilroy">
              <span>{{ earn4 }}</span
              ><span class="ml-6px">UNC</span>
            </div>
          </div>
          <div class="text-[#ffffff99] text-16px font-Gilroy mt-8px">
            POOL4 EARNED
          </div>
          <div class="mt-29px">
            <div class="btn-sm btn-bg w-93px" @click="playwithdrawReward4">
              {{ $t('head.get') }}
            </div>
          </div>
          <div
            class="border border-[#ffffff33] rounded-16px mt-16px h-44px flex items-center"
          >
            <van-field
              class="rounded-full inp1"
              label-width="0"
              v-model="amount4"
              :placeholder="$t('head.inputAmount')"
            ></van-field>
          </div>
          <div class="mt-16px flex">
            <div
              @click="onApprove4"
              class="h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba814d] from-opacity-30 to-[#1453bf4d] to-opacity-30 border border-[#1453bf4d]"
            >
              <img
                src="~@/assets/new/06.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t('head.aa7') }}</span>
            </div>

            <div
              @click="onDeposit4"
              class="ml-25px h-34px flex items-center justify-center flex-1 rounded-12px bg-gradient-to-l from-[#2cba81] to-[#1453bf]"
            >
              <img
                src="~@/assets/new/20.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t('head.stake') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/index'
import Headers from '@/components/headers.vue'
import { BigNumber } from 'bignumber.js'
export default {
  mixins: [mixin],
  components: {
    Headers,
  },
  data() {
    return {
      earn3: '0',
      earn4: '0',
      balance: '0',
      balanceStar: '0',
      amount3: '',
      amount4: '',
      hashTotal:0,
      userHash:0
    }
  },
  computed:{
    teamHash(){
      return this.hashTotal - this.userHash
    }
  },
  methods: {
    getPool4HashTotal(){
      this.PIPOOL4_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          this.hashTotal = parseInt((res / 1e18))
        })
      this.PIPOOL4_contract.methods
        .getDeposit(this.global.account)
        .call()
        .then((res) => {
          this.userHash = parseInt((res / 1e18))
        })
    },
    getearn() {
      this.PIPOOL3_contract.methods
        .earned(this.global.account)
        .call()
        .then((res) => {
          this.earn3 = (res / 1e18).toFixed(6)
        })
      this.PIPOOL4_contract.methods
        .earned(this.global.account)
        .call()
        .then((res) => {
          this.earn4 = (res / 1e18).toFixed(6)
        })
    },
    getBalance() {
      this.getPool4HashTotal()
      this.PI_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          this.balance = (res / 10 ** 18).toFixed(6)
          if (res == 0) {
            this.balance = 0
          }
        })

      this.STAR_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          this.balanceStar = (res / 10 ** 18).toFixed(6)
          if (res == 0) {
            this.balanceStar = 0
          }
        })
    },
    onApprove3() {
      if (this.amount3 == '' || this.amount3 <= 0)
        return this.$toast(this.$t('head.sl'))

      const price = (this.amount3 * 10 ** 18 * 100).toFixed(0)

      this.PI_contract.methods
        .approve(
          this.pool.pi_pool3,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('APPROVE SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onApprove4() {
      if (this.amount4 == '' || this.amount4 <= 0)
        return this.$toast(this.$t('head.sl'))

      const price = (this.amount4 * 10 ** 18 * 100).toFixed(0)

      this.STAR_contract.methods
        .approve(
          this.pool.pi_pool4,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('APPROVE SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onDeposit3() {
      if (this.amount3 == '' || this.amount3 <= 0)
        return this.$toast(this.$t('head.sl'))
      this.PIPOOL3_contract.methods
        .deposit(this.global.web3.utils.toWei(this.amount3 + '', 'ether'))
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
          this.amount3 = null
          this.getBalance()
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onDeposit4() {
      if (this.amount4 == '' || this.amount4 <= 0)
        return this.$toast(this.$t('head.sl'))
      this.PIPOOL4_contract.methods
        .deposit(this.global.web3.utils.toWei(this.amount4 + '', 'ether'))
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.amount4 = null
          this.$toast('SUCCESS')
          this.getBalance()
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    playwithdrawReward3() {
      this.PIPOOL3_contract.methods
        .withdrawReward()
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
          this.getBalance()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    playwithdrawReward4() {
      this.PIPOOL4_contract.methods
        .withdrawReward()
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.getBalance()
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
  watch: {
    'global.account'() {
      this.PIPOOL3_contract = new this.global.web3.eth.Contract(
        this.poolabi.PIPOOL3_abi,
        this.pool.pi_pool3
      )
      this.PIPOOL4_contract = new this.global.web3.eth.Contract(
        this.poolabi.PIPOOL4_abi,
        this.pool.pi_pool4
      )
      this.PI_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.pool.pi
      )
      this.STAR_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.pool.STAR
      )
      this.getBalance()
      this.getearn()
    },
  },
}
</script>

<style scoped></style>
